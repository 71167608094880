import React, { useState } from "react";
import {
    Box,
    Stack,
    FormControl,
    Select,
    FormLabel,
    FormHelperText,
} from "@chakra-ui/core";

const TicketArea = ({ saveAreaId }) => {
    const [area, setArea] = useState(11);

    const changeAreaHandler = (e) => {
        const actualValue = e.target.value;
        setArea(actualValue);
        saveAreaId(actualValue);
    };

    const submitHandler = (e) => e.preventDefault();

    return (
        <Box
            maxW="lg"
            borderWidth="1px"
            rounded="lg"
            overflow="hidden"
            bg="white"
            p="3"
            mx="auto"
            w="100%"
            shadow="0 8px 6px -6px rgba(0, 0, 0, 0.4)"
        >
            <form onSubmit={submitHandler}>
                <Stack spacing={2} isInline align="center" justify="center">
                    <FormControl w="70%">
                        <FormLabel htmlFor="area_id">
                            Area di Lavorazione
                        </FormLabel>
                        <Select
                            id="area_id"
                            name="area_id"
                            value={area}
                            onChange={changeAreaHandler}
                        >
                            <option value="11">Commesse</option>
                            <option value="12">Industria</option>
                        </Select>
                        <FormHelperText id="area-helper">
                            Impostare l'Area di Lavorazione
                        </FormHelperText>
                    </FormControl>
                </Stack>
            </form>
        </Box>
    );
};

export default TicketArea;
