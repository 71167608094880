import React, { useState } from "react";
import { Flex, Progress, Stack, Heading } from "@chakra-ui/core";

import useInterval from "../../hooks/useInterval";

const Loading = () => {
    const [bar, setBar] = useState(0);

    useInterval(() => {
        setBar(bar + 10);
    }, 250);

    return (
        <Flex
            w="100%"
            h="100%"
            justify="center"
            align="center"
            background="white"
        >
            <Stack minW="sm">
                <Progress hasStripe isAnimated size="lg" value={bar} />
                <Heading as="h1">Loading...</Heading>
            </Stack>
        </Flex>
    );
};

export default Loading;
