/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Switch, Route, useHistory } from "react-router-dom";

import { UserProvider } from "../../contexts/userContext";
import PrivateRoute from "../PrivateRoute/PrivateRoute";
import Layout from "../Layout/Layout";
import Login from "../Login/Login";
import NotFound from "../Error/404";
import Loading from "../Loading/Loading";
import useBackend from "../../hooks/useBackend";

function App() {
    const { data, isLoading, error, fetchData } = useBackend({
        method: "get",
        url: "/auth/token",
    });
    const [user, setUser] = useState({ isLoggedIn: false });
    const history = useHistory();

    useEffect(() => {
        (() => {
            fetchData();
        })();
    }, []);

    useEffect(() => {
        if (data && data.message) {
            setUser({
                username: data.user.name,
                userId: data.user.userId,
                passwordChange: data.user.passwordChange,
                groups: data.user.groups,
                isLoggedIn: true,
            });
        }
    }, [data]);

    useEffect(() => {
        if (error && error.message) {
            setUser({ isLoggedIn: false });
        }
    }, [error]);

    useEffect(() => {
        if (user.isLoggedIn) {
            history.push("/board");
        } else {
            history.push("/login");
        }
    }, [user]);

    return (
        <UserProvider value={{ user, setUser }}>
            {!isLoading ? (
                <Switch>
                    <PrivateRoute path="/board">
                        <Layout />
                    </PrivateRoute>
                    <Route path="/login">
                        <Login />
                    </Route>
                    <Route path="*">
                        <NotFound />
                    </Route>
                </Switch>
            ) : (
                <Loading />
            )}
        </UserProvider>
    );
}

export default App;
