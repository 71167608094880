import React from "react";
import ReactDOM from "react-dom";
import { ThemeProvider, CSSReset } from "@chakra-ui/core";
import { BrowserRouter as Router } from "react-router-dom";

import customTheme from "./theme";
import "./index.css";
import App from "./components/App/App";

ReactDOM.render(
    <React.StrictMode>
        <Router>
            <ThemeProvider theme={customTheme}>
                <CSSReset />
                <App />
            </ThemeProvider>
        </Router>
    </React.StrictMode>,
    document.getElementById("root")
);
