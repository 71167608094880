import { theme } from "@chakra-ui/core";

export default {
    ...theme,
    fontSizes: {
        xs: "0.55rem",
        sm: "0.775rem",
        md: "1rem",
        lg: "1.25rem",
        xl: "1.45rem",
        "2xl": "1.9rem",
        "3xl": "2.2rem",
        "4xl": "2.25rem",
        "5xl": "3rem",
        "6xl": "4rem",
    },
};
