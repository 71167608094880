import React, { useState, useEffect, useContext } from "react";
import { List, Box, Heading, Stack } from "@chakra-ui/core";
import { useLocation, Link } from "react-router-dom";

import UserContext from "../../contexts/userContext";
import menuData from "./menuData.json";
import MenuItem from "../MenuItem/MenuItem";

const UserMenu = () => {
    const location = useLocation();
    const { user } = useContext(UserContext);
    const [currentPage, setCurrentPage] = useState(location.pathname);

    useEffect(() => {
        setCurrentPage(location.pathname);
    }, [location.pathname]);
    /**
     * Confronta le permission per visualizzare le funzionalità con quelle
     * passate lato server nel token.
     *
     * @param {array} requested requested permission array
     * @param {array} granted user permissions
     */
    const checkForPermission = (requested, granted) => {
        let answer = false;

        const grantedArray = granted.map((perm) => perm.gruppo_id);

        for (let i = 0; i < requested.length; i++) {
            if (grantedArray.includes(requested[i])) return true;
        }
        return answer;
    };

    return (
        <Box>
            {menuData.map((grp) => {
                if (checkForPermission(grp.permission, user.groups)) {
                    return (
                        <Box
                            key={grp.id}
                            p="2"
                            my="1"
                            backgroundColor="blue.600"
                            rounded="md"
                            borderColor="blue.700"
                            borderWidth="1px"
                        >
                            <Stack spacing={3}>
                                <Heading as="h2" size="sm">
                                    {grp.title}
                                </Heading>
                                <List spacing={3} pl="2">
                                    {grp.apps.map((item) => {
                                        return (
                                            <Link key={item.id} to={item.url}>
                                                <MenuItem
                                                    title={item.title}
                                                    url={item.url}
                                                    currentPage={currentPage}
                                                />
                                            </Link>
                                        );
                                    })}
                                </List>
                            </Stack>
                        </Box>
                    );
                }
                return (
                    <Box
                        p="2"
                        my="1"
                        backgroundColor="blue.600"
                        rounded="md"
                        borderColor="blue.700"
                        borderWidth="1px"
                    >
                        <Heading as="h2" size="md">
                            Nessun record
                        </Heading>
                    </Box>
                );
            })}
        </Box>
    );
};

export default UserMenu;
