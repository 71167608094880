import React, { useState } from "react";
import { Box, Heading, Divider, Stack, Button } from "@chakra-ui/core";
import { FaArrowRight, FaArrowLeft, FaArrowCircleRight } from "react-icons/fa";

import TicketPhaseSwitcher from "../TicketPhaseSwitcher/TicketPhaseSwitcher";

const TicketBuilder = ({
    area_id,
    biglietto_id,
    orders_p,
    ticketData_p,
    process_phase,
}) => {
    const [processPhase, setProcessPhase] = useState(process_phase || 1);

    return (
        <Box>
            <Heading as="h1">Biglietto di Produzione</Heading>
            <Divider />
            <Box>
                <TicketPhaseSwitcher phase={processPhase} />
            </Box>
            <Stack
                isInline
                justify={processPhase > 1 ? "space-between" : "flex-end"}
            >
                {processPhase > 1 ? (
                    <Button
                        variantColor="blue"
                        rightIcon={FaArrowLeft}
                        onClick={() => setProcessPhase((prev) => prev - 1)}
                    >
                        Indietro
                    </Button>
                ) : null}
                {processPhase < 3 ? (
                    <Button
                        variantColor="blue"
                        rightIcon={FaArrowRight}
                        onClick={() => setProcessPhase((prev) => prev + 1)}
                    >
                        Avanti
                    </Button>
                ) : null}
                {processPhase === 3 ? (
                    <Button variantColor="blue" rightIcon={FaArrowCircleRight}>
                        Concludi
                    </Button>
                ) : null}
            </Stack>
        </Box>
    );
};

export default TicketBuilder;
