import React from "react";
import { Box, Heading, Divider } from "@chakra-ui/core";

import ChangePassword from "../ChangePassword/ChangePassword";

const UserOptions = () => {
    return (
        <Box>
            <Heading as="h1">Gestione Profilo</Heading>
            <Divider />
            <ChangePassword />
        </Box>
    );
};

export default UserOptions;
