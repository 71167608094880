import axios from "axios";

const { REACT_APP_BACKEND_HOST, NODE_ENV } = process.env;

let instance;

if (NODE_ENV === "development") {
    instance = axios.create({
        withCredentials: true,
    });
} else {
    instance = axios.create({
        baseURL: `https://${REACT_APP_BACKEND_HOST}`,
        withCredentials: true,
    });
}

export default instance;
