import React from "react";
import { Flex, Box } from "@chakra-ui/core";

import LogoImg from "../../images/logo.png";

const Logo = () => (
    <Flex w="100%" h="auto" justify="center" align="center">
        <Box w="100%" h="auto" maxW="101px">
            <img src={LogoImg} alt="Gottifredi Maffioli" />
        </Box>
    </Flex>
);

export default Logo;
