import React, { useState, useEffect, useContext } from "react";
import {
    Box,
    Stack,
    Heading,
    FormControl,
    FormLabel,
    Input,
    FormHelperText,
    Button,
    useToast,
} from "@chakra-ui/core";

import useBackend from "../../hooks/useBackend";
import UserContext from "../../contexts/userContext";

const ChangePassword = ({ onPasswordChange }) => {
    const toast = useToast();
    const { user } = useContext(UserContext);
    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const { data, isLoading, error, fetchData } = useBackend({
        method: "patch",
        url: `/user/${user.userId}/password`,
        data: { oldPassword, updatedPassword: newPassword },
    });

    const formSubmitHandler = async (e) => {
        e.preventDefault();
        try {
            if (newPassword !== confirmPassword) {
                throw new Error(
                    "La nuova password non coincide con la conferma"
                );
            }
            await fetchData();
        } catch (err) {
            toast({
                title: "Cambio Password",
                description: err.message,
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        }
    };

    useEffect(() => {
        if (data && data.message && onPasswordChange) {
            onPasswordChange("success", data);
        }

        if (error && error.message && onPasswordChange) {
            onPasswordChange("error", error);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, error]);

    return (
        <form onSubmit={formSubmitHandler}>
            <Box
                maxW="lg"
                shadow="0 8px 6px -6px rgba(0, 0, 0, 0.4)"
                p="4"
                borderWidth="1px"
                rounded="lg"
                overflow="hidden"
                m="auto"
            >
                <Stack spacing="2" justify="center">
                    <Heading as="h2" size="md">
                        Cambio Password
                    </Heading>
                    <FormControl isRequired>
                        <FormLabel htmlFor="oldPassword">
                            Password Corrente
                        </FormLabel>
                        <Input
                            type="password"
                            id="oldPassword"
                            aria-describedby="oldPassword"
                            errorBorderColor="crimson"
                            value={oldPassword}
                            onChange={(e) => setOldPassword(e.target.value)}
                        />
                        <FormHelperText id="newPassword-helper">
                            La password attualmente in uso
                        </FormHelperText>
                    </FormControl>
                    <FormControl isRequired>
                        <FormLabel htmlFor="newPassword">
                            Nuova Password
                        </FormLabel>
                        <Input
                            type="password"
                            id="newPassword"
                            aria-describedby="newPassword"
                            errorBorderColor="crimson"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                        />
                        <FormHelperText id="newPassword-helper">
                            La password che si vuole avere dopo la modifica
                        </FormHelperText>
                    </FormControl>
                    <FormControl isRequired>
                        <FormLabel htmlFor="confirmPassword">
                            Conferma Password
                        </FormLabel>
                        <Input
                            type="password"
                            id="confirmPassword"
                            aria-describedby="confirmPassword"
                            errorBorderColor="crimson"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                        <FormHelperText id="confirmPassword-helper">
                            Conferma la nuova password
                        </FormHelperText>
                    </FormControl>
                    <Button
                        type="submit"
                        variantColor="blue"
                        size="lg"
                        isLoading={isLoading}
                    >
                        Esegui
                    </Button>
                    {error ? (
                        <Box
                            bg="red.100"
                            borderColor="red.500"
                            borderWidth="1px"
                            rounded="lg"
                            color="red.500"
                            p={4}
                        >
                            {error.message}
                        </Box>
                    ) : null}
                    {data ? (
                        <Box
                            bg="green.100"
                            borderColor="green.500"
                            borderWidth="1px"
                            rounded="lg"
                            color="green.500"
                            p={4}
                        >
                            {data.message}
                        </Box>
                    ) : null}
                </Stack>
            </Box>
        </form>
    );
};

export default ChangePassword;
