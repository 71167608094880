import React, { useState, useEffect } from "react";
import {
    Box,
    Divider,
    Heading,
    Stack,
    FormControl,
    FormLabel,
    FormHelperText,
    Input,
    Select,
    Button,
    useToast,
    Skeleton,
} from "@chakra-ui/core";
import { FaPlus, FaPrint, FaTrash, FaWrench, FaClone } from "react-icons/fa";

import DataGrid from "../DataGrid/DataGrid";
import useBackend from "../../hooks/useBackend";

const SearchTicket = () => {
    const toast = useToast();
    const [order, setOrder] = useState("");
    const [area, setArea] = useState(10);
    const headers = ["Lavorazione", "Biglietto", "Tipo Lavorazione", "Opzioni"];
    const controls = [
        { title: "Fase", icon: FaPlus },
        { title: "Ordine", icon: FaPlus },
        { title: "Duplica", icon: FaClone },
        { title: "Stampa", icon: FaPrint },
        { title: "Modifica", icon: FaWrench },
        { title: "Cancella", icon: FaTrash },
    ];
    const [biglietti, setBiglietti] = useState([]);
    const { data, isLoading, error, fetchData } = useBackend({
        method: "get",
        url: `/gm/biglietto/${order}/${area}`,
    });

    const formSubmitHandler = async (e) => {
        e.preventDefault();

        try {
            await fetchData();
        } catch (err) {
            toast({
                title: "Ricerca Biglietti",
                description: err.message,
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        }
    };

    useEffect(() => {
        if (data && data.message) {
            setBiglietti([...data.data]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    useEffect(() => {
        if (error && error.message) {
            toast({
                title: "Ricerca Biglietti",
                description: error.message,
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error]);

    return (
        <Box>
            <Heading as="h1">Ricerca Biglietto di Produzione</Heading>
            <Divider />
            <Box>
                <form onSubmit={formSubmitHandler}>
                    <Stack
                        isInline
                        spacing={2}
                        align="center"
                        justify="space-evenly"
                    >
                        <FormControl isRequired w="100%">
                            <FormLabel htmlFor="ordine">
                                Numero Ordine
                            </FormLabel>
                            <Input
                                type="text"
                                id="ordine"
                                aria-describedby="ordine"
                                errorBorderColor="crimson"
                                value={order}
                                onChange={(e) => setOrder(e.target.value)}
                            />
                            <FormHelperText id="ordine-helper">
                                Ordine "OR" di Business Center
                            </FormHelperText>
                        </FormControl>
                        <FormControl isRequired w="50%">
                            <FormLabel htmlFor="area">
                                Area Lavorazione
                            </FormLabel>
                            <Select
                                id="area"
                                aria-describedby="area"
                                errorBorderColor="crimson"
                                value={area}
                                onChange={(e) => setArea(e.target.value)}
                            >
                                <option value="10">Qualsiasi</option>
                                <option value="11">Commesse</option>
                                <option value="12">Industria</option>
                            </Select>
                            <FormHelperText id="area-helper">
                                Area di lavorazione
                            </FormHelperText>
                        </FormControl>
                        <Button
                            w="30%"
                            type="submit"
                            variantColor="blue"
                            size="lg"
                            isLoading={isLoading}
                        >
                            Cerca
                        </Button>
                    </Stack>
                </form>
            </Box>
            <Divider />
            {isLoading ? (
                <Box>
                    <Skeleton
                        colorStart="blue.700"
                        colorEnd="white"
                        height="20px"
                    />
                    <Skeleton
                        colorStart="blue.700"
                        colorEnd="white"
                        height="20px"
                    />
                    <Skeleton
                        colorStart="blue.700"
                        colorEnd="white"
                        height="20px"
                    />
                </Box>
            ) : (
                <Box>
                    {biglietti.length > 0 ? (
                        <DataGrid
                            head={headers}
                            body={biglietti}
                            controls={controls}
                        />
                    ) : (
                        <p>Nessun Biglietto Trovato</p>
                    )}
                </Box>
            )}
        </Box>
    );
};

export default SearchTicket;
