import React from "react";
import { Stack, Button, Box } from "@chakra-ui/core";

import style from "./DataGrid.module.css";

const DataGrid = ({ head, body, controls }) => {
    return (
        <Box
            as="table"
            w="auto"
            minW="80%"
            mx="auto"
            borderWidth="1px"
            borderColor="blue.700"
            p="3"
            shadow="0 8px 6px -6px rgba(0, 0, 0, 0.4)"
            mt="8"
        >
            <Box as="thead" bg="blue.700" color="white">
                <tr>
                    {head.map((header) => (
                        <Box as="th" key={header} p="3">
                            {header}
                        </Box>
                    ))}
                </tr>
            </Box>
            <tbody>
                {body.map((field) => {
                    return (
                        <tr key={field.Fase} className={style.Body}>
                            {Object.keys(field).map((key) => {
                                return (
                                    <Box
                                        as="td"
                                        textAlign="center"
                                        key={key + field.Fase}
                                    >
                                        {field[key]}
                                    </Box>
                                );
                            })}
                            <td>
                                <Stack isInline justify="center" align="center">
                                    {controls.map((control) => (
                                        <Button
                                            variantColor="blue"
                                            key={control.title + field.Fase}
                                            rightIcon={
                                                control.icon
                                                    ? control.icon
                                                    : null
                                            }
                                        >
                                            {control.title}
                                        </Button>
                                    ))}
                                </Stack>
                            </td>
                        </tr>
                    );
                })}
            </tbody>
        </Box>
    );
};

export default DataGrid;
