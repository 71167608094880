import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import UserContext from "../../contexts/userContext";

function PrivateRoute({ children, ...rest }) {
    // eslint-disable-next-line no-unused-vars
    const { user } = useContext(UserContext);

    return (
        <Route
            {...rest}
            render={({ location }) =>
                user.isLoggedIn ? (
                    children
                ) : (
                    <Redirect
                        to={{ pathname: "/login", state: { from: location } }}
                    />
                )
            }
        />
    );
}

export default PrivateRoute;
