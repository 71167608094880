/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import {
    Flex,
    Box,
    Heading,
    Stack,
    FormControl,
    FormLabel,
    FormHelperText,
    Input,
    Button,
    useToast,
} from "@chakra-ui/core";

import UserContext from "../../contexts/userContext";
import useBackend from "../../hooks/useBackend";

const Login = () => {
    const history = useHistory();
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const { user, setUser } = useContext(UserContext);
    const toast = useToast();
    const { data, isLoading, error, fetchData } = useBackend({
        method: "post",
        url: "/auth/log-in",
        data: { name: username, pswd: password },
    });

    async function submitHandler(event) {
        event.preventDefault();
        try {
            await fetchData();
        } catch (err) {
            console.log(err);
            (() =>
                toast({
                    title: "Login",
                    description: err.message,
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                }))();
        }
    }

    useEffect(() => {
        if (data && data.user) {
            (() =>
                toast({
                    title: "Login",
                    description: data.message,
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                }))();
            setUser({
                username: data.user.name,
                userId: data.user.userId,
                passwordChange: data.user.passwordChange,
                groups: data.user.groups,
                isLoggedIn: true,
            });
        }
    }, [data]);

    useEffect(() => {
        if (user && user.isLoggedIn) {
            if (data === null) {
                (() =>
                    toast({
                        title: "Login",
                        description: "Sessione ripresa",
                        status: "success",
                        duration: 3000,
                        isClosable: true,
                    }))();
            }

            history.push("/");
        }
    }, [user]);

    return (
        <Flex justify="center" align="center" w="100%" h="100%">
            <Box
                maxW="md"
                borderWidth="1px"
                rounded="lg"
                overflow="hidden"
                bg="white"
                p="3"
                w="100%"
                minH="sm"
                shadow="0 8px 6px -6px rgba(0, 0, 0, 0.4)"
            >
                <form onSubmit={submitHandler}>
                    <Stack spacing={2}>
                        <Heading as="h1" size="xl">
                            Account Log-In
                        </Heading>
                        <FormControl isRequired>
                            <FormLabel htmlFor="username">Username</FormLabel>
                            <Input
                                type="text"
                                id="username"
                                aria-describedby="username"
                                errorBorderColor="crimson"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                            />
                            <FormHelperText id="username-helper">
                                Display Name
                            </FormHelperText>
                        </FormControl>
                        <FormControl isRequired>
                            <FormLabel htmlFor="password">Password</FormLabel>
                            <Input
                                type="password"
                                id="password"
                                aria-describedby="password"
                                errorBorderColor="crimson"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            <FormHelperText id="password-helper">
                                Password
                            </FormHelperText>
                        </FormControl>
                        <Button
                            type="submit"
                            variantColor="blue"
                            size="lg"
                            isLoading={isLoading}
                        >
                            LogIn
                        </Button>
                        {error ? (
                            <Box
                                bg="red.100"
                                borderColor="red.500"
                                borderWidth="1px"
                                rounded="lg"
                                color="red.500"
                                p={4}
                            >
                                {error.hasOwnProperty("message")
                                    ? error.message
                                    : JSON.stringify(error)}
                            </Box>
                        ) : null}
                        {data ? (
                            <Box
                                bg="green.100"
                                borderColor="green.500"
                                borderWidth="1px"
                                rounded="lg"
                                color="green.500"
                                p={4}
                            >
                                {data.message}
                            </Box>
                        ) : null}
                    </Stack>
                </form>
            </Box>
        </Flex>
    );
};

export default Login;
