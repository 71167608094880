import React from "react";

import TicketArea from "../TicketPhases/TicketArea";

const TickerPhaseSwitcher = ({ phase }) => {
    let Component;

    switch (phase) {
        case 1:
            Component = () => <TicketArea />;
            break;
        case 2:
            Component = () => <p>2</p>;
            break;
        case 3:
            Component = () => <p>3</p>;
            break;
        default:
            Component = () => <p>Error</p>;
    }

    return <Component />;
};

export default TickerPhaseSwitcher;
