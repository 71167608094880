/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";

import axios from "../axios/config";

function useBackend(options) {
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);

    async function fetchData() {
        try {
            setError(null);
            setData(null);
            setIsLoading(true);
            let response = await axios(options);
            setData(response.data);
        } catch (err) {
            setError(err.response.data);
        } finally {
            setIsLoading(false);
        }
    }

    return { data, isLoading, error, fetchData };
}

export default useBackend;
