import React, { useState, useContext } from "react";
import {
    Flex,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    useToast,
} from "@chakra-ui/core";

import UserContext from "../../contexts/userContext";
import Sidebar from "../Sidebar/Sidebar";
import Main from "../Main/Main";
import ChangePassword from "../ChangePassword/ChangePassword";

const Layout = () => {
    const toast = useToast();
    const { user, setUser } = useContext(UserContext);
    const [showModal, setShowModal] = useState(true);

    const passwordChangeHandler = (type, data) => {
        if (type === "success") {
            setShowModal(false);
            setUser({ ...user, passwordChange: 0 });
        }
        toast({
            title: "Cambio Password",
            description: data.message,
            status: type,
            duration: 3000,
            isClosable: true,
        });
    };

    return (
        <Flex bg="gray.100" w="100%" h="100%">
            <Sidebar />
            <Main />
            {user.passwordChange === 1 ? (
                <Modal
                    isOpen={showModal}
                    isCentered
                    blockScrollOnMount
                    useInert
                    size="lg"
                    closeOnOverlayClick={false}
                    closeOnEsc={false}
                    addAriaLabels
                    p="4"
                >
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>Cambio Password Obbligatorio</ModalHeader>
                        <ModalBody>
                            <ChangePassword
                                onPasswordChange={passwordChangeHandler}
                            />
                        </ModalBody>
                    </ModalContent>
                </Modal>
            ) : null}
        </Flex>
    );
};

export default Layout;
