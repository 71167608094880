import React from "react";
import { ListItem, Box, Stack } from "@chakra-ui/core";
import { FaArrowAltCircleRight } from "react-icons/fa";

import style from "./MenuItem.module.css";

const MenuItem = ({ title, currentPage, url }) => {
    const isSelected = currentPage === url;
    return (
        <ListItem>
            <Stack spacing={2} isInline align="center">
                <Box
                    as={FaArrowAltCircleRight}
                    size="16px"
                    color={isSelected ? "yellow.300" : "blue.700"}
                />
                <Box
                    className={style.Item}
                    borderBottomWidth="2px"
                    borderBottomColor={isSelected ? "white" : "transparent"}
                    fontWeight={isSelected ? "600" : "400"}
                    borderBottomStyle="dashed"
                >
                    {title}
                </Box>
            </Stack>
        </ListItem>
    );
};

export default MenuItem;
