import React from "react";
import { Flex, Box, Divider } from "@chakra-ui/core";

import Logo from "../Logo/Logo";
import UserPanel from "../UserPanel/UserPanel";
import UserMenu from "../UserMenu/UserMenu";

const Navigation = () => (
    <Box
        as="aside"
        bg="blue.500"
        w="100%"
        maxW="18rem"
        color="white"
        p="4"
        borderColor="blue.600"
        borderWidth="0"
        borderRightWidth="2px"
    >
        <Flex direction="column">
            <Logo />
            <Divider />
            <UserPanel />
            <Divider />
            <UserMenu />
        </Flex>
    </Box>
);

export default Navigation;
