import React, { useContext } from "react";
import { Box } from "@chakra-ui/core";
import { Switch } from "react-router-dom";

import PrivateRoute from "../PrivateRoute/PrivateRoute";
import UserContext from "../../contexts/userContext";
import UserOptions from "../UserOptions/UserOptions";
import SearchTicket from "../SearchTicket/SearchTiket";
import TicketBuilder from "../TicketBuilder/TicketBuilder";

const Main = () => {
    // eslint-disable-next-line no-unused-vars
    const { user, setUser } = useContext(UserContext);

    return (
        <Box as="main" bg="white" size="100%" maxW="100%" p="4">
            <Switch>
                <PrivateRoute path="/board/user/options">
                    <UserOptions />
                </PrivateRoute>
                <PrivateRoute path="/board/gm/crea-biglietto">
                    <TicketBuilder />
                </PrivateRoute>
                <PrivateRoute path="/board/gm/ricerca-biglietto">
                    <SearchTicket />
                </PrivateRoute>
                <PrivateRoute path="*">
                    <p>Main</p>
                </PrivateRoute>
            </Switch>
        </Box>
    );
};

export default Main;
