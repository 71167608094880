/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import { Flex, Box, Tooltip, Heading, useToast } from "@chakra-ui/core";
import {
    FaIdCardAlt,
    FaCogs,
    FaSignOutAlt,
    FaQuestionCircle,
} from "react-icons/fa";

import useBackend from "../../hooks/useBackend";
import UserContext from "../../contexts/userContext";
import styles from "./UserPanel.module.css";

const UserPanel = () => {
    const history = useHistory();
    const { user, setUser } = useContext(UserContext);
    const toast = useToast();
    // eslint-disable-next-line no-unused-vars
    const { data, isLoading, error, fetchData } = useBackend({
        method: "get",
        url: "/auth/log-out",
    });

    const logoutClickHandler = async (event) => {
        event.preventDefault();
        try {
            await fetchData();
        } catch (err) {
            (() =>
                toast({
                    title: "Logout",
                    description: err.message,
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                }))();
            history.push("/board");
        }
    };

    useEffect(() => {
        if (data && data.message) {
            (() =>
                toast({
                    title: "Logout",
                    description: data.message,
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                }))();
            setUser({ isLoggedIn: false });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    useEffect(() => {
        if (error && error.message) {
            (() =>
                toast({
                    title: "Logout",
                    description: error.message,
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                }))();
        }
    }, [error]);

    return (
        <Flex
            as="ul"
            justify="space-around"
            w="100%"
            py="4"
            m="0"
            style={{ listStyle: "none" }}
            flexWrap="wrap"
        >
            <li style={{ flexBasis: "100%", marginBottom: "0.7rem" }}>
                {user.isLoggedIn ? (
                    <Box textAlign="center">
                        <Heading as="h4">{user.username}</Heading>
                    </Box>
                ) : (
                    <Flex w="100%" justify="center">
                        <Tooltip
                            bg="#0059a1"
                            label="User Placeholder"
                            aria-label="User Placeholder"
                            placement="top"
                            shouldWrapChildren
                            hasArrow
                        >
                            <Box as={FaQuestionCircle} size="24px" />
                        </Tooltip>
                    </Flex>
                )}
            </li>
            <li className={styles.listItemIcon}>
                <Link to="/user/profile">
                    <Tooltip
                        bg="#0059a1"
                        placement="bottom"
                        label="Profilo Utente"
                        aria-label="Profilo Utente"
                        shouldWrapChildren
                        hasArrow
                    >
                        <Box as={FaIdCardAlt} size="18px" />
                    </Tooltip>
                </Link>
            </li>
            <li className={styles.listItemIcon}>
                <Link to="/board/user/options">
                    <Tooltip
                        bg="#0059a1"
                        placement="bottom"
                        label="Opzioni Utente"
                        aria-label="Opzioni Utente"
                        shouldWrapChildren
                        hasArrow
                    >
                        <Box as={FaCogs} size="18px" />
                    </Tooltip>
                </Link>
            </li>
            <li className={styles.listItemIcon} onClick={logoutClickHandler}>
                <Tooltip
                    bg="#0059a1"
                    placement="bottom"
                    label="Logout"
                    aria-label="Logout"
                    shouldWrapChildren
                    hasArrow
                >
                    <Box as={FaSignOutAlt} size="18px" />
                </Tooltip>
            </li>
        </Flex>
    );
};

export default UserPanel;
